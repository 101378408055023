import React from "react"

function ContactForm(props) {

    const { containerId = 'contact-form-container', hideHeading = false, title = 'Optimise Your Supply Chain' } = props;

    React.useEffect(() => {
        window.hbspt.forms.create({
            portalId: '48416295',
            formId: 'c827c244-d1af-4a9b-a879-05b1a7e666b0',
            target: `#${containerId}`
        });
    }, [containerId]);

    console.log(containerId);


    return (
        <React.Fragment>
            <div className="section-title contactus" hidden={hideHeading}>
                <h3 className="font-weight-bold">{title}</h3>
                <p className="subtitle">Choose Prozo as your supply chain partner and experience the difference that operational excellence, technological integration, and a client-centric approach can make. Let us help you unlock the full potential of your supply chain and drive your business forward.</p>
            </div>
            <div className="content pt-2" id={containerId} />
        </React.Fragment>
    )
}

export default ContactForm;