const allMenus = [
    {
        name: 'Fulfilment',
        subMenus: [
            {
                name: 'Fulfilment Services',
                link: '/fulfilment-services',
                items: [
                    { name: 'Pick, Pack & Ship', link: '/fulfilment-services#INNER__Pick__Pack___Ship' },
                    { name: 'Inventory Management', link: '/fulfilment-services#INNER__Inventory_Management' },
                    { name: 'Packaging Material Management', link: '/fulfilment-services#INNER__Packaging_Material_Management' },
                    { name: 'Repackaging', link: '/fulfilment-services#INNER__Repackaging' },
                    { name: 'Labeling & Barcoding', link: '/fulfilment-services#INNER__Labeling___Barcoding' },
                    { name: 'Return Processing Centres (RPC)', link: '/fulfilment-services#INNER__Return_Processing_Centres__RPC_' },
                ]
            },
            {
                name: 'Demand Channels',
                link: '/fulfilment-services#DemandChannels',
                items: [
                    { name: 'B2C Marketplaces', link: '/fulfilment-services#INNER__B2C_Marketplaces' },
                    { name: 'D2C Webstores', link: '/fulfilment-services#INNER__D2C_Webstores' },
                    { name: 'Quick Commerce Fulfilment', link: '/fulfilment-services#INNER__Quick_Commerce' },
                    { name: 'General and Modern Trade', link: '/fulfilment-services#INNER__General_and_Modern_Trade' },
                    { name: 'B2B Orders to  Marketplaces', link: '/fulfilment-services#INNER__B2B_Orders_to_Marketplaces' },
                ]
            },
            {
                name: 'Warehousing Models',
                link: '/fulfilment-services#WarehousingModels',
                items: [
                    { name: 'Shared Warehousing', link: '/fulfilment-services#INNER__Shared_Warehousing' },
                    { name: 'Dedicated Warehousing', link: '/fulfilment-services#INNER__Dedicated_Warehousing' },
                    { name: 'On Demand Warehousing', link: '/fulfilment-services#INNER__On_Demand_Warehousing' },
                    { name: 'Managed Warehousing', link: '/fulfilment-services#INNER__Managed_Warehousing' }
                ]
            },
            {
                name: 'Storage Categories',
                link: '/fulfilment-services#StorageCategories',
                items: [
                    { name: 'Ambient Storage', link: '/fulfilment-services#INNER__Ambient_Storage' },
                    { name: 'Air Conditioned', link: '/fulfilment-services#INNER__Air_Conditioned_Storage' },
                    { name: 'Refrigerated', link: '/fulfilment-services#INNER__Refrigerated_Storage' },
                    { name: 'Cold / Frozen', link: '/fulfilment-services#INNER__Cold_Frozen_Storage' }
                ]
            }
        ]
    },
    {
        name: 'Logistics',
        subMenus: [
            {
                name: 'Delivery Options',
                link: '/shipping/d2c-freight',
                items: [
                    { name: 'Surface & Air Delivery', link: '/shipping/d2c-freight#INNER__Surface___Air' },
                    { name: 'Same City SDD & NDD', link: '/shipping/d2c-freight#INNER__Same_City_SDD___NDD' },
                    { name: 'Metro to Metro NDD', link: '/shipping/d2c-freight#INNER__Metro_to_Metro_NDD' },
                    { name: 'Hyperlocal Freight', link: '/shipping/d2c-freight#INNER__Hyperlocal' },

                    { name: 'Pre-Shipping Solutions', link: '/shipping/d2c-freight#Pre-Shipping', isHeading: true },
                    { name: 'Integrations Ecosystem', link: '/shipping/d2c-freight#Pre-Shipping_Integrations' },
                    { name: 'Intelligent Courier Selection', link: '/shipping/d2c-freight#Pre-Shipping_Intelligent_Courier_Selection' },
                    { name: 'RTO Reduction Service', link: '/shipping/d2c-freight#Pre-Shipping_RTO_Reduction' },
                ]
            },

            {
                name: 'Post-Shipping Solutions',
                link: '/shipping/d2c-freight#Post-Shipping',
                items: [
                    { name: 'NDR Management', link: '/shipping/d2c-freight#Post-Shipping_NDR' },
                    { name: 'Returns Management System (RMS)', link: '/shipping/d2c-freight#Post-Shipping_RETURNS' },
                    /* { name: 'Performance & Customer Experience', link: '#', isHeading: true },
                    { name: 'Logistics Control Tower', link: '#' },
                    { name: 'ProPilot - Your AI Ally', link: '#' },
                    { name: 'Branded Consumer Experience', link: '#' } */

                ]
            },
            {
                name: 'Enterprise Services',
                link: '/shipping/d2c-freight#EnterpriseServices',
                items: [
                    { name: 'API Integration Suite', link: '/shipping/d2c-freight#TAB__API_Integration_Suite' },
                    { name: 'Automated Weight Discrepancy Management', link: '/shipping/d2c-freight#TAB__Automated_Weight_Discrepancy_Management' },
                    { name: 'Express COD Remittance Options', link: '/shipping/d2c-freight#TAB__Express_COD_Remittance_Options' },
                    { name: 'Performance Linked Commercials', link: '/shipping/d2c-freight#TAB__Performance_Linked_Commercials' },
                ]
            },
            {
                name: 'Express Freight (PTL / LTL)',
                link: '/shipping/express-freight',
                items: [
                    { name: 'Real time shipment booking', link: '/shipping/express-freight#TAB__Real_Time_Shipment_Booking' },
                    { name: 'Online Pickup Request Based Booking', link: '/shipping/express-freight#TAB__Online_Pickup_Request_Based_Booking' },
                    { name: 'Whatsapp Based Pickup Requests', link: '/shipping/express-freight#TAB__WhatsApp_Based_Pickup_Requests' },
                    { name: 'Appointment Based Deliveries', link: '/shipping/express-freight#TAB__Appointment_Based_Deliveries' },
                    { name: 'Dedicated Milk Run Vehicles', link: '/shipping/express-freight#TAB__Dedicated_Milk_Run_Vehicles' },
                    { name: 'Surface & Air Modes', link: '/shipping/express-freight#TAB__Surface___Air_Modes' },
                ]
            }
        ]
    },
    {
        name: 'Technology',
        subMenus: [
            {
                name: 'Order Management System',
                link: '/technology/order-management-system',
                items: [
                    { name: 'Marketplace, Webstores & B2B Order Management', link: '/technology/order-management-system#LIST__Marketplace__Webstores___B2B_Order_Management' },
                    { name: '50+ Marketplace Integrations', link: '/technology/order-management-system#LIST__50__Marketplace_Integrations' },
                    { name: 'Intelligent Order Routing', link: '/technology/order-management-system#LIST__Intelligent_Order_Routing' },
                    { name: 'Common Pool Of Inventory', link: '/technology/order-management-system#LIST__Common_Pool_Of_Inventory' },
                    { name: 'ERP Integrations', link: '/technology/order-management-system#LIST__ERP_Integrations' },
                    { name: 'Webstores Integrations', link: '/technology/order-management-system#LIST__Webstores_Integrations' },
                    { name: 'B2B Workflows', link: '/technology/order-management-system#LIST__B2B_Workflows' },
                ]
            },
            {
                name: 'Warehouse Management System',
                link: '/technology/warehouse-management-system',
                items: [
                    { name: 'EAN & USN Based Operations', link: '/technology/warehouse-management-system#LIST__EAN_and_USN_Based_Operations' },
                    { name: 'Wireless Scan Based Operations', link: '/technology/warehouse-management-system#LIST__Wireless_Scan_Based_Operations' },
                    { name: 'Inventory Management', link: '/technology/warehouse-management-system#LIST__Inventory_Management' },
                    { name: 'Multiple Picking & Putaway Algorithms', link: '/technology/warehouse-management-system#LIST__Multiple_Picking___Putaway_Algorithms' },
                    { name: 'Role Based Access Control', link: '/technology/warehouse-management-system#LIST__Role_Based_Access_Control' },
                    { name: 'Shelf Life Management', link: '/technology/warehouse-management-system#LIST__Shelf_Life_Management' },
                    { name: 'Webcam & CCTV Integration', link: '/technology/warehouse-management-system#LIST__Webcam___CCTV_Integrations' },
                    { name: 'Barcoding Solutions', link: '/technology/warehouse-management-system#LIST__Barcoding_Solutions' },
                ]
            },
            {
                name: 'Freight Management System',
                link: '/technology/freight-management-system',
                items: [
                    { name: 'ProShip Feature List', link: '/technology/freight-management-system#ProShipFeatureList' },
                    { name: 'Integration Ecosystem', link: '/technology/freight-management-system#Integration___Ecosystem' },
                    { name: 'Escalation Management System', link: '/technology/freight-management-system#INNER__Escalation_Management_System' },
                    { name: 'MPS Shipments', link: '/technology/freight-management-system#INNER__MPS_Shipments' },
                    { name: 'ProPilot - Your AI Ally', isHeading: true, link: '/technology/propilot' },
                    { name: 'Express (LTL / PTL) Freight Platform', isHeading: true, link: '/technology/express-proship' },
                ]
            }
        ]
    },

    {
        name: 'Control Tower',
        link: '/technology/control-tower',
        subMenus: [
            {
                name: 'Control Tower',
                link: '/technology/control-tower',
                items: [
                    { name: 'Warehousing Performance & SLA Management', link: '/technology/control-tower#WarehousingPerformanceSLAManagement' },
                    { name: 'SLA Breach Prevention', link: '/technology/control-tower#AlertsAndNotifications' },
                    { name: 'Manpower Planning', link: '/technology/control-tower#ManpowerPlanning' },
                    /*  { name: 'Cycle Count Control Tower', link: '/technology/control-tower#CycleCountControlTower' }, */
                    { name: 'Inventory Health', link: '/technology/control-tower#CycleCountControlTower' },
                    { name: 'Logistics Performance & SLA Management', link: '/technology/control-tower#LogisticsPerformanceSLAManagement' },
                ]
            },
            {
                name: 'Integrated Supply Chains',
                link: '/technology/integrated-supply-chain',
                items: [
                    { name: 'Order to Delivery SLA Analytics', link: '/technology/integrated-supply-chain#INNER__Order_to_Delivery_Tech' },
                    { name: 'SLA & Sub SLA Tracking', link: '/technology/integrated-supply-chain#INNER__SLA___Sub_SLA_Tracking' },
                    { name: 'Pickup & Handover Anomaly Detection', link: '/technology/integrated-supply-chain#INNER__Pickup___Handover_Anomaly_Detection' },
                    { name: 'Integrated Returns Loss Prevention', link: '/technology/integrated-supply-chain#INNER__Automated_Return_Loss_Prevention' },
                    { name: 'Command Centre', link: '/technology/integrated-supply-chain#INNER__Control_and_Command_Center' },
                ]
            }
        ]
    },

    {
        name: 'Company',
        subMenus: [
            {
                items: [
                    { name: 'Clients', link: '/about-us/our-clients' },
                    { name: 'Case Studies', link: '/about-us/case-study' },
                    { name: 'How We Work', link: '/about-us/contact' },
                    { name: 'Prozo Fulfilment Network', link: '/about-us/pfn' },
                    /* { name: 'Career Opportunities', link: 'https://prozo.zohorecruit.in/jobs/Careers' }, */
                    { name: 'Prozo In News', link: '/media' },
                ]
            }
        ]
    },
    {
        name: 'Blogs',
        link: 'https://blogs.prozo.com/',
    }
]

export default allMenus;