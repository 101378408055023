import React from "react";
import ContactForm from "./ContactForm";
import Video from "./Video";

function ContactFormModal() {

    return (
        <div className="modal fade" id="contact" role="dialog">
            <div
                className="modal-dialog modal-xl modal-dialog-centered"
                role="document"
            >
                <div className="modal-content mp">
                    {/* Modal body */}
                    <div className="modal-body prozo-modal">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <div className="section">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-5 col-md-5 col-xs-12">
                                        <div className="section-title">
                                            <div className="mb-5">
                                                <Video src={"/media/logo.svg"} height={50} alt="" />
                                                <p className="text-dark text-small mt-2">
                                                    <small>Democratizing Access to Best-in-Class, Future-Ready Supply Chains</small>
                                                </p>
                                            </div>
                                            <h4 className="mb-4 prozo-subheading">
                                                End to End Supply Chain Management
                                            </h4>
                                            <p className="para-desc">
                                                {/*?php echo isset($text1) ? $text1 : "Accelerate your B2B, B2C and D2C sales by handing over your entire supply chain fulfilment responsibilities to us, we assure reliable services within defined SLAs. Write to us now!!" ?*/}
                                            </p>
                                        </div>
                                        <div className="bg-transparent mt-3">
                                            <p className="m-0">Corporate Office</p>
                                            <small style={{ fontSize: 13 }}>
                                                Prozo Integrated Logistics Private Limited Enkay Tower, Plot
                                                number B&amp;B1, A Wing,Third Floor, Vanijya Nikunj, Udyog
                                                Vihar Phase V, Gurugram, Haryana – 122016
                                            </small>
                                        </div>
                                    </div>
                                    <div className="col-lg-7 col-md-7 col-xs-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                        <ContactForm hideHeading containerId="modal-form-container" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactFormModal;