import { AnchorLink as Link } from "gatsby-plugin-anchor-links";
import React from "react";
import ContactForm from "./ContactForm";
import Video from "./Video";
import ContactFormModal from "./ContactFormModal";

function Footer({ addClass }) {

    return (
        <>
            <footer className={`footer ${addClass || ""}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-3 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                            <h4 className="text-light footer-head">Solutions</h4>
                            <ul className="list-unstyled footer-list mt-4">
                                <li>
                                    <Link to="/fulfilment-services" className="text-foot">
                                        <i className="mdi mdi-chevron-right mr-1" /> Fulfilment
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/shipping/d2c-freight" className="text-foot">
                                        <i className="mdi mdi-chevron-right mr-1" /> Prozo Logistics Network
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/shipping/d2c-freight/#Pre-Shipping" className="text-foot">
                                        <i className="mdi mdi-chevron-right mr-1" /> Pre-Shipping Solutions
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/shipping/d2c-freight/#Post-Shipping" className="text-foot">
                                        <i className="mdi mdi-chevron-right mr-1" /> Post-Shipping Solutions
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/technology/order-management-system/" className="text-foot">
                                        <i className="mdi mdi-chevron-right mr-1" /> Order Management System
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/technology/warehouse-management-system/" className="text-foot">
                                        <i className="mdi mdi-chevron-right mr-1" /> Warehouse Management System
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/technology/freight-management-system/" className="text-foot">
                                        <i className="mdi mdi-chevron-right mr-1" /> Freight Management System
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/technology/control-tower" className="text-foot">
                                        <i className="mdi mdi-chevron-right mr-1" /> Control Tower
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/technology/integrated-supply-chain" className="text-foot">
                                        <i className="mdi mdi-chevron-right mr-1" /> Integrated Suply Chains
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-3 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <h4 className="text-light footer-head">Company</h4>
                            <ul className="list-unstyled footer-list mt-4">
                                {<li>
                                    <a href="https://prozo.zohorecruit.in/jobs/Careers" target="_blank" className="text-foot">
                                        <i className="mdi mdi-chevron-right mr-1" /> Careers
                                    </a>
                                </li>}
                                <li>
                                    <Link to="/about-us/case-study" className="text-foot">
                                        <i className="mdi mdi-chevron-right mr-1" /> Case Study
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/about-us/pfn" className="text-foot">
                                        <i className="mdi mdi-chevron-right mr-1" /> Prozo Fulfilment
                                        Network
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/about-us/our-clients" className="text-foot">
                                        <i className="mdi mdi-chevron-right mr-1" /> Our Clients
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/privacy-policy" className="text-foot">
                                        <i className="mdi mdi-chevron-right mr-1" /> Privacy Policy
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/refund-policy" className="text-foot">
                                        <i className="mdi mdi-chevron-right mr-1" /> Refund Policy
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/toc" className="text-foot">
                                        <i className="mdi mdi-chevron-right mr-1" /> Terms & Conditions
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/compliance" className="text-foot">
                                        <i className="mdi mdi-chevron-right mr-1" /> Compliance
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        {/*end col*/}
                        <div className="col-lg-4 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <h4 className="text-light footer-head">Contact Us</h4>
                            <ul className="list-unstyled footer-list mt-4">
                                <li>
                                    <a href="tel:+9311847248" className="text-foot">
                                        <i className="mdi mdi-phone mr-1" /> +91 9311847248
                                    </a>
                                </li>
                                <li>
                                    <a href="mailto:sales@prozo.com" className="text-foot">
                                        <i className="mdi mdi-email mr-1" /> sales@prozo.com
                                    </a>
                                </li>
                                <li>&nbsp;</li>
                                <li className="text-foot">
                                    <b>Corporate Office:</b> Prozo Integrated Logistics Private Limited Enkay
                                    Tower, Plot number B&amp;B1, A Wing,Third Floor, Vanijya Nikunj,
                                    Udyog Vihar Phase V, Gurugram, Haryana – 122016
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
            <footer className={`footer footer-bar ${addClass || ""}`}>
                <div className="container text-center">
                    <div className="row align-items-center">
                        <div className="col-sm-6">
                            <div className="text-sm-left">
                                <p className="mb-0 " style={{ fontSize: 12 }}>
                                    © {new Date().getFullYear()} Prozo. All Rights Reserved
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <ul className="list-unstyled social-icon social text-sm-right mb-0 mt-4">
                                <li className="list-inline-item">
                                    <a
                                        target="_blank"
                                        href="https://www.facebook.com/prozodotcom"
                                        className="rounded bg-primary border-0"
                                    >
                                        <i
                                            data-feather="facebook"
                                            className="fea icon-sm fea-social"
                                        />
                                    </a>
                                </li>
                                <li className="list-inline-item">
                                    <a
                                        target="_blank"
                                        href="https://twitter.com/prozodotcom"
                                        className="rounded bg-primary border-0"
                                    >
                                        <i
                                            data-feather="twitter"
                                            className="fea icon-sm fea-social"
                                        />
                                    </a>
                                </li>
                                <li className="list-inline-item">
                                    <a
                                        target="_blank"
                                        href="https://www.linkedin.com/company/prozo"
                                        className="rounded bg-primary border-0"
                                    >
                                        <i
                                            data-feather="linkedin"
                                            className="fea icon-sm fea-social"
                                        />
                                    </a>
                                </li>
                            </ul>
                        </div>
                        {/*end col*/}
                    </div>
                    {/*end row*/}
                </div>
                {/*end container*/}
            </footer>

            <ContactFormModal />

            <div className="cookie-overlay p-4 d-none">
                <div
                    className="d-flex align-items-center"
                    style={{ justifyContent: "space-between" }}
                >
                    <div className="mr-3">
                        We use cookies on our website to see how you interact with it. By
                        accepting, you agree to our use of such cookies.
                        <Link to="/privacy-policy">Privacy Policy</Link>
                    </div>
                    <div>
                        <button
                            type="button"
                            className="close-cookies"
                            style={{
                                background: "transparent",
                                border: "1px solid #000",
                                borderRadius: 3,
                                color: "#000",
                                fontSize: 12
                            }}
                        >
                            Close
                        </button>
                        <button
                            className="accept-cookies"
                            style={{
                                background: "#000",
                                border: 0,
                                borderRadius: 3,
                                color: "#fff",
                                fontSize: 12
                            }}
                        >
                            Accept All
                        </button>
                    </div>
                </div>
            </div>
            <button type="button" className="btn btn-icon btn-soft-primary back-to-top" id="scrollTop">
                <i data-feather="arrow-up" className="icons" />
            </button>
        </>

    )
}

export default Footer;