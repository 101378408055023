import { AnchorLink as Link } from "gatsby-plugin-anchor-links";
import React from "react";
import { allMenus } from "../data";

function TopMenus() {

    return (
        <div id="navigation">
            <ul className="navigation-menu">
                {allMenus.map((menu, i) => (
                    <li className={menu?.subMenus ? "has-submenu" : null} key={i}>

                        <Link to={menu?.link || '#'} title={menu.name} />

                        {menu?.subMenus?.length > 0 && (
                            <span className="menu-arrow" />
                        )}

                        {(menu?.subMenus?.length > 1) ? (
                            <div className="submenu mega">
                                <div className="d-lg-flex d-block">

                                    {menu.subMenus.map((subMenu, i) => (
                                        <ul className="column" key={i}>
                                            <li key={subMenu.name}>
                                                <Link to={subMenu?.link || "#"} title={subMenu.name} className="mheading" />
                                            </li>

                                            {subMenu?.items?.map((item, i) => (
                                                <li key={i}>
                                                    <Link title={item.name} className={item?.isHeading ? "mheading" : null} to={item?.link || "#"} />
                                                </li>
                                            ))}

                                        </ul>
                                    ))}

                                </div>
                            </div>
                        ) : (
                            <>
                                {menu.subMenus?.map((subMenu, i) => (
                                    <ul className="submenu" key={i}>
                                        {subMenu.name && (
                                            <li key={subMenu.name}>
                                                <Link to={subMenu?.link || "#"} title={subMenu.name} className="mheading" />
                                            </li>
                                        )}
                                        {subMenu?.items?.map((item, i) => (
                                            <li key={i}>
                                                <Link title={item.name} className={item?.isHeading ? "mheading" : null} to={item?.link || "#"} />
                                            </li>
                                        ))}

                                    </ul>
                                ))}
                            </>
                        )}
                    </li>
                ))}

            </ul>
            <div className="buy-menu-btn d-none">
                <button className="btn btn-primary" data-toggle="modal" data-target="#contact">
                    Get Started
                </button>
            </div>
        </div>
    )

}

export default TopMenus;